// src/components/sections/ProjectsSection.js

import React from 'react';

const ProjectsSection = () => {
  return (
    <div className="projects-section">
      <h2>Projects</h2>
      {/* Add your project content here */}
    </div>
  );
};

export default ProjectsSection;
